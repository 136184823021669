var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        visible: _vm.visible,
        closable: true,
        "mask-closable": false,
        size: "normal",
        title: (_vm.mode == "add" ? "添加" : "编辑") + "字段",
        "ok-text": "确认",
        "z-index": 2,
        "overflow-auto": "",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "字段名称", prop: "fieldName" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.form.createType == 0,
                  placeholder: "请输入1-20个字符",
                },
                model: {
                  value: _vm.form.fieldName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fieldName", $$v)
                  },
                  expression: "form.fieldName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "类型", prop: "fieldType" } },
            [
              _c("a-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  options: _vm.options,
                  placeholder: "请选择类型",
                  disabled: _vm.form.createType == 0 || _vm.mode != "add",
                },
                on: { change: _vm.fieldTypeChange },
                model: {
                  value: _vm.form.fieldType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fieldType", $$v)
                  },
                  expression: "form.fieldType",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "表格列宽", prop: "width" } },
            [
              _c("numberInput", {
                attrs: {
                  "is-zero": false,
                  max: 999,
                  disabled: _vm.form.createType == 0,
                  placeholder: "请输入表格的表头列宽(5个汉字约为100宽度)",
                },
                model: {
                  value: _vm.form.width,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "width",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.width",
                },
              }),
            ],
            1
          ),
          _vm.form.fieldType == "1"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "文本长度", prop: "fieldLength" } },
                [
                  _c("numberInput", {
                    attrs: {
                      "is-zero": false,
                      disabled: _vm.form.createType == 0,
                      placeholder: "请输入文本长度",
                    },
                    model: {
                      value: _vm.form.fieldLength,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "fieldLength",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.fieldLength",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.fieldType == "3"
            ? _c(
                "div",
                [
                  _vm._l(_vm.form.customOptions, function (key, index) {
                    return [
                      _c(
                        "a-form-model-item",
                        {
                          key: "select" + index,
                          ref: "select" + index,
                          refInFor: true,
                          staticClass: "optionsItem",
                          attrs: {
                            label: index === 0 ? "选项" : "",
                            prop: "customOptions." + index + ".label",
                            rules: {
                              required: true,
                              validator: _vm.selectBlur,
                              trigger: "blur",
                              index: index,
                            },
                          },
                        },
                        [
                          _c("a-input", {
                            staticClass: "int",
                            attrs: {
                              "max-length": 50,
                              disabled: _vm.form.createType == 0,
                              placeholder: "选项名称",
                            },
                            model: {
                              value: key.label,
                              callback: function ($$v) {
                                _vm.$set(key, "label", $$v)
                              },
                              expression: "key.label",
                            },
                          }),
                          _c(
                            "svg",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.form.createType == 1 &&
                                    _vm.form.customOptions.length > 1,
                                  expression:
                                    "form.createType == 1 && form.customOptions.length > 1",
                                },
                              ],
                              staticClass: "iconpark-icon delBtn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [_c("use", { attrs: { href: "#delete-one" } })]
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.form.createType == 1 &&
                            _vm.form.customOptions.length < 100,
                          expression:
                            "form.createType == 1 && form.customOptions.length < 100",
                        },
                      ],
                    },
                    [
                      _c("a", { on: { click: _vm.handleAdd } }, [
                        _vm._v("添加选项"),
                      ]),
                    ]
                  ),
                ],
                2
              )
            : _vm._e(),
          _c("a-form-model-item", { attrs: { label: "管理系统属性" } }, [
            _c(
              "div",
              { staticClass: "switch-panel" },
              [
                _c("a-switch", {
                  attrs: {
                    size: "default",
                    "checked-children": "可见",
                    "un-checked-children": "隐藏",
                    disabled: _vm.form.editable == "0",
                  },
                  model: {
                    value: _vm.manageSwitch,
                    callback: function ($$v) {
                      _vm.manageSwitch = $$v
                    },
                    expression: "manageSwitch",
                  },
                }),
                _c("a-checkbox-group", {
                  attrs: {
                    disabled: _vm.form.editable == "0" || !_vm.manageSwitch,
                    options: _vm.sysOptions,
                  },
                  model: {
                    value: _vm.manageChecks,
                    callback: function ($$v) {
                      _vm.manageChecks = $$v
                    },
                    expression: "manageChecks",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("a-form-model-item", { attrs: { label: "员工端属性" } }, [
            _c(
              "div",
              { staticClass: "switch-panel" },
              [
                _c("a-switch", {
                  attrs: {
                    size: "default",
                    "checked-children": "可见",
                    "un-checked-children": "隐藏",
                    disabled: _vm.form.editable == "0",
                  },
                  model: {
                    value: _vm.staffSwitch,
                    callback: function ($$v) {
                      _vm.staffSwitch = $$v
                    },
                    expression: "staffSwitch",
                  },
                }),
                _c("a-checkbox-group", {
                  attrs: {
                    disabled: _vm.form.editable == "0" || !_vm.staffSwitch,
                    options: _vm.staffOptions,
                  },
                  model: {
                    value: _vm.staffChecks,
                    callback: function ($$v) {
                      _vm.staffChecks = $$v
                    },
                    expression: "staffChecks",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("a-form-model-item", { attrs: { label: "入职登记表属性" } }, [
            _c(
              "div",
              { staticClass: "switch-panel" },
              [
                _c("a-switch", {
                  attrs: {
                    size: "default",
                    "checked-children": "可见",
                    "un-checked-children": "隐藏",
                    disabled: _vm.form.editable == "0",
                  },
                  model: {
                    value: _vm.entryFormSwitch,
                    callback: function ($$v) {
                      _vm.entryFormSwitch = $$v
                    },
                    expression: "entryFormSwitch",
                  },
                }),
                _c("a-checkbox-group", {
                  attrs: {
                    disabled: _vm.form.editable == "0" || !_vm.entryFormSwitch,
                    options: _vm.improveOptions,
                  },
                  model: {
                    value: _vm.entryFormChecks,
                    callback: function ($$v) {
                      _vm.entryFormChecks = $$v
                    },
                    expression: "entryFormChecks",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }